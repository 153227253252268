import { NgModule } from "@angular/core";
import { CarrouselComponent } from "./carrousel/carrousel.component";
import { HomeComponent } from "./home.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations:[HomeComponent, NavbarComponent, CarrouselComponent, FooterComponent],
    exports: [HomeComponent, FooterComponent]
})
export class HomeModule {

}