import { Component } from "@angular/core";


@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ['./home.scss']
})
 
export class HomeComponent {

}